<template>
  <div class="container vld-parent">
    <loading
      :active.sync="isLoading"
      :is-full-page="true"
      color="#114C8E"
    ></loading>
    <b-row>
      <h1 class="text-primary">Messages</h1>
    </b-row>
    <b-row>
      <b-card style="width: 100%">
        <b-row class="mb-2">
          <b-col>
            <b-button
              v-if="canCreateAdminMessages"
              variant="primary"
              @click="redirectToAddNew()"
            >
              <i class="fa fa-plus mr-1"></i> Add New Message
            </b-button>
          </b-col>

          <b-col md="6" class="my-1">
            <b-form inline class="float-md-right">
              <label class="mr-sm-2 col-form-label-sm" for="perPageSelect"
                >Per page:</label
              >
              <b-form-select
                v-model="perPage"
                id="perPageSelect"
                size="sm"
                :options="pageOptions"
              ></b-form-select>
            </b-form>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-table
              :items="messages"
              :fields="fields"
              responsive="sm"
              striped
              outlined
              :current-page="currentPage"
              :per-page="perPage"
              :sort-direction="sortDirection"
            >
              <template v-slot:cell(actions)="data">
                <b-button
                  v-if="canUpdateAdminMessages"
                  size="sm"
                  variant="primary"
                  class="mb-1 mr-2"
                  id="btnEdit"
                  @click="sendToEdit(data.item.messageId)"
                >
                  <i class="fa fa-info-circle mr-1"></i>
                  Details
                </b-button>

                <b-button
                  v-if="canDeleteAdminMessages"
                  size="sm"
                  variant="danger"
                  class="mb-1 btn-sm"
                  style="cursor:pointer"
                  @click="confirmDelete(data.item.messageId)"
                >
                  <i class="fa fa-trash mr-1"></i>
                  Delete
                </b-button>
              </template>
            </b-table>

            <b-pagination
              v-model="currentPage"
              :total-rows="messages.length"
              :per-page="perPage"
              last-number
              class="my-2"
            ></b-pagination>
            Total Records : {{ this.messages.length }}
          </b-col>
        </b-row>
      </b-card>
    </b-row>
  </div>
</template>

<script>
import { handleRequestError } from "@/shared/utils/response-error-handler";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { RepositoryFactory } from "@/services/RepositoryFactory";
const MessagesRepository = RepositoryFactory.get("messagesRepository");

import {
  canViewAdminMessages,
  canCreateAdminMessages,
  canUpdateAdminMessages,
  canDeleteAdminMessages
} from "@/shared/utils/permissions";

export default {
  components: {
    Loading,
    Event
  },
  data() {
    return {
      isLoading: false,
      showModal: false,
      modalTitle: null,
      messages: [],
      perPage: 50,
      totalRows: 1,
      currentPage: 1,
      pageOptions: [5, 10, 25, 50, 100, 500],
      filter: null,
      sortDirection: "asc",
      fields: [
        { key: "title", label: "Title", sortable: true },
        { key: "category", label: "Category", sortable: true },
        { key: "dateCreatedUtc", label: "Date Sent", sortable: true },
        { key: "actions", label: "Actions" }
      ]
    };
  },
  methods: {
    redirectToAddNew() {
      this.$router.push({
        name: "admin-create-message"
      });
    },
    sendToEdit(id) {
      this.$router.push({
        name: "admin-edit-message",
        params: { id: id }
      });
    },
    confirmDelete(id) {
      this.$snotify.error(
        "Are you sure you want to delete this record",
        "Delete",
        {
          backdrop: 0.3,
          timeout: 0,
          closeOnClick: false,
          position: "centerCenter",
          buttons: [
            {
              text: "Ok",
              action: async toast => {
                await this.deleteMessage(id);
                this.$snotify.remove(toast.id);
              },
              bold: true
            },
            { text: "Cancel", action: null, bold: false }
          ]
        }
      );
    },
    async deleteMessage(id) {
      await MessagesRepository.delete(id);
      this.messages = this.messages.filter(w => w.messageId != id);
    }
  },
  async created() {
    this.isLoading = true;

    this.$root.$on("toggleModalState", val => {
      this.showModal = val;
    });

    try {
      var requestData = {
        skip: 0,
        take: 1000000
      };

      let result = await MessagesRepository.get(requestData);
      this.messages = result.results;
    } catch (error) {
      var errorMessage = handleRequestError(error);
      this.$snotify.error(errorMessage, "Error");
    }
    this.isLoading = false;
    this.totalRows = this.messages.length;
  },
  mounted() {},
  computed: {
    canViewAdminMessages() {
      return canViewAdminMessages(this.$store);
    },
    canCreateAdminMessages() {
      return canCreateAdminMessages(this.$store);
    },
    canUpdateAdminMessages() {
      return canUpdateAdminMessages(this.$store);
    },
    canDeleteAdminMessages() {
      return canDeleteAdminMessages(this.$store);
    }
  }
};
</script>

<style scoped></style>
